<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container>
      <v-row class="mt-2">
        <v-col>
          <h2>国交省登録情報 データ取込</h2>
        </v-col>
      </v-row>
      <!-- 国交省受領ファイル -->
      <v-row>
        <v-col cols="4" sm="4" md="4" lg="3">
          <v-file-input
            :background-color="isEnter ? 'info' : 'transparent'"
            label="国交省受領ファイル"
            v-model="mlitFile"
            dense
            :readonly="false"
            :clearable="true"
            :rules="[]"
            :loading="loading"
            truncate-length="50"
            placeholder="ファイルを選択します。"
            persistent-placeholder
            accept=".xlsx"
          ></v-file-input>
        </v-col>
        <v-col cols="2" sm="2" md="2" lg="2">
          <DatePicker
            label="作成時点"
            propClass="right-input"
            v-model="inputDate"
            format="YYYY年M月D日時点"
            selectType="date"
            :rules="[]"
            :clearable="false"
            dense
          ></DatePicker>
        </v-col>
        <v-col cols="2" sm="2" md="2" lg="2">
          <v-btn
            class="btn_font primary" 
            width="150" 
            v-on:click="uploadMlitFile()"
            :disabled="loading || (mlitFile == null) || inputDate == null || inputDate == ''"
          >
            <v-icon :small="true">mdi-import</v-icon>データ取込
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <!-- <v-col cols="2" sm="2" md="2" lg="2">
          <v-btn 
            class=""
            color="primary"
            width="150"
            :disabled="loading || (inputData.length <= 0)"
            @click="cancel()">キャンセル</v-btn>
        </v-col> -->
        <v-col cols="2" sm="2" md="2" lg="2">
          <v-btn 
            class="" 
            color="primary" 
            width="150" 
            :disabled="loading || (inputData.length <= 0)" 
            @click="registData()"
          >
            <v-icon :small="true">mdi-check-bold</v-icon>データ保存
          </v-btn>
        </v-col>
      </v-row>

    </v-container>

    <v-container v-if="inputData.length <= 0">
      <v-row>
        <v-col class="pt-0 pb-0 mb-n2">国交省受領ファイルを選択しデータを取り込みます。</v-col>
      </v-row>
    </v-container>
    <v-container v-else-if="filterItems.length <= 0">
      <v-row>
        <v-col class="pt-0 pb-0 mb-n2">全件数 {{inputData.length}}件 うちエラー件数 {{filterItems.length}}件</v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-0 mb-n2">データを保存できます。</v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-col class="pt-0 pb-0 mb-n2">全件数 {{inputData.length}}件 うちエラー件数 {{filterItems.length}}件</v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0 mb-n2">エラー箇所を確認・修正してデータを保存できます。</v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table
            :headers="headers"
            :items="filterItems"
            :loading="loading"
            :dense='true'
            :mobile-breakpoint="0"
            :disable-pagination='true'
            :hide-default-header='false'
            :hide-default-footer='true'
            :fixed-header='true'
            :disable-sort='false'
            locale="ja-jp"
            loading-text="読込中"
            no-data-text="データがありません。"
            class="table-border no-hover-effect"
            height="calc(100vh - 340px)"
          >
            <template v-slot:body="{items}">
              <tbody>
                <tr v-for="(item, index) in items" :key="index" :class="['tr-data', (item.disable == 1) ? 'disable-color' : 'enable']" >
                  <td :class="['center', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- <input type='checkbox' v-model="item.disable" :true-value="1" :false-value="0" /> -->
                    <v-simple-checkbox 
                      v-model="item.disable" 
                      :true-value="true" 
                      :false-value="false" 
                      :readonly="!editMode" 
                      :ripple="false"
                      @click="()=>{$refs.form.validate();}"
                    ></v-simple-checkbox>
                  </td>
<!-- 
                  <td :class="['center', (item.disable == 1) ? 'disable-color' : (item.checkCount > 0) ? 'warning-color' : '']">
                    {{item.checkCount > 0 ? '×' : '○'}}
                  </td>
-->
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.manage_no}} -->
                    <NumberTextField
                      :propClass="['mb-4']"
                      v-model="item.manage_no"
                      :readonly="!editMode || item.disable"
                      :rules="[maxValIf((item.disable != true), 99999), minValIf((item.disable != true), 0)]"
                      dense
                      :toLocaleFlg="false"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.office_mlit}} -->
                    <NumberTextField
                      :propClass="['mb-4']"
                      v-model="item.office_mlit"
                      :readonly="!editMode || item.disable"
                      :rules="[maxValIf((item.disable != true), 99), minValIf((item.disable != true), 0)]"
                      dense
                      :toLocaleFlg="false"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.office_mlit_branch}} -->
                    <NumberTextField
                      :propClass="['mb-4']"
                      v-model="item.office_mlit_branch"
                      :readonly="!editMode || item.disable"
                      :rules="[maxValIf((item.disable != true), 99), minValIf((item.disable != true), 0)]"
                      dense
                      :toLocaleFlg="false"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.office_pref}} -->
                    <NumberTextField
                      :propClass="['mb-4']"
                      v-model="item.office_pref"
                      :readonly="!editMode || item.disable"
                      :rules="[maxValIf((item.disable != true), 47), minValIf((item.disable != true), 1)]"
                      dense
                      :toLocaleFlg="false"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.ticket}} -->
                    <NumberTextField
                      :propClass="['mb-4']"
                      v-model="item.ticket"
                      :readonly="!editMode || item.disable"
                      :rules="[requiredIf(() => !item.disable), maxValIf((item.disable != true), 2), minValIf((item.disable != true), 1)]"
                      dense
                      :toLocaleFlg="false"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.regist_no}} -->
                    <NumberTextField
                      :propClass="[(item.chk_regist_no) ? 'mt-0 pt-0' : 'mb-4']"
                      v-model="item.regist_no"
                      :readonly="!editMode || item.disable"
                      :rules="[requiredIf(() => !item.disable), maxValIf((item.disable != true), 99999), minValIf((item.disable != true), 0), checkUnique(index, (item.disable != true))]"
                      dense
                      :toLocaleFlg="false"
                      :changeEvt="()=>{$refs.form.validate();}"
                      :hide-details="item.chk_regist_no"
                    ></NumberTextField>
                    <span v-if="item.chk_regist_no" class="caption text-error">前回:{{item.prev_regist_no}}</span>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.company_name}} -->
                    <v-text-field
                      :class="(item.chk_capital) ? 'mt-0 pt-0' : 'mb-4'"
                      v-model="item.company_name"
                      :readonly="!editMode || item.disable"
                      :rules="[maxLengthIf((item.disable != true), 100)]"
                      dense
                      :hide-details="item.chk_capital"
                      :maxLength="100"
                    ></v-text-field>
                    <span v-if="item.chk_capital" class="caption text-error">前回:{{item.prev_company_name}}</span>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.capital}} -->
                    <NumberTextField
                      :propClass="[(item.chk_capital) ? 'mt-0 pt-0' : 'mb-4']"
                      v-model="item.capital"
                      :readonly="!editMode || item.disable"
                      :rules="[maxValLocaleJPIf((item.disable != true), 9999999999), minValLocaleJPIf((item.disable != true), 0)]"
                      dense
                      :hide-details="item.chk_capital"
                    ></NumberTextField>
                    <span v-if="item.chk_capital" class="caption text-error">前回:{{item.prev_capital}}</span>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.office_main}} -->
                    <NumberTextField
                      :propClass="['mb-4']"
                      v-model="item.office_main"
                      :readonly="!editMode || item.disable"
                      :rules="[maxValIf((item.disable != true), 999), minValIf((item.disable != true), 0)]"
                      dense
                      :toLocaleFlg="false"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.office_main_mlit}} -->
                    <NumberTextField
                      :propClass="['mb-4']"
                      v-model="item.office_main_mlit"
                      :readonly="!editMode || item.disable"
                      :rules="[maxValIf((item.disable != true), 99), minValIf((item.disable != true), 0)]"
                      dense
                      :toLocaleFlg="false"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.office_no}} -->
                    <NumberTextField
                      :propClass="['mb-4']"
                      v-model="item.office_no"
                      :readonly="!editMode || item.disable"
                      :rules="[requiredIf(() => !item.disable), maxValIf((item.disable != true), 999), minValIf((item.disable != true), 0), checkUnique(index, (item.disable != true))]"
                      dense
                      :toLocaleFlg="false"
                      :changeEvt="()=>{$refs.form.validate();}"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.office_name}} -->
                    <v-text-field
                      class="mb-4"
                      v-model="item.office_name"
                      :readonly="!editMode || item.disable"
                      :rules="[maxLengthIf((item.disable != true), 100)]"
                      dense
                      :maxLength="100"
                    ></v-text-field>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.office_address}} -->
                    <v-text-field
                      :class="(item.chk_warehouse_no) ? 'mt-0 pt-0' : 'mb-4'"
                      v-model="item.office_address"
                      :readonly="!editMode || item.disable"
                      :rules="[maxLengthIf((item.disable != true), 100)]"
                      dense
                      :hide-details="item.chk_warehouse_no"
                      :maxLength="100"
                    ></v-text-field>
                    <span v-if="item.chk_warehouse_no" class="caption text-error">前回:{{item.prev_office_address}}</span>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.warehouse_no}} -->
                    <NumberTextField
                      :propClass="[(item.chk_warehouse_no) ? 'mt-0 pt-0' : 'mb-4']"
                      v-model="item.warehouse_no"
                      :readonly="!editMode || item.disable"
                      :rules="[requiredIf(() => !item.disable), maxValIf((item.disable != true), 999), minValIf((item.disable != true), 0), checkUnique(index, (item.disable != true))]"
                      dense
                      :hide-details="item.chk_warehouse_no"
                      :toLocaleFlg="false"
                      :changeEvt="()=>{$refs.form.validate();}"
                    ></NumberTextField>
                    <span v-if="item.chk_warehouse_no" class="caption text-error">前回:{{item.prev_warehouse_no}}</span>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.warehouse_name}} -->
                    <v-text-field
                      class="mb-4"
                      v-model="item.warehouse_name"
                      :readonly="!editMode || item.disable"
                      :rules="[maxLengthIf((item.disable != true), 100)]"
                      dense
                      :maxLength="100"
                    ></v-text-field>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.installation_date}} -->
                    <DatePicker
                      :propClass="(item.chk_installation_date) ? 'right-input mt-0 pt-0' : 'right-input mb-4'"
                      v-model="item.installation_date"
                      format="YYYY/MM/DD"
                      selectType="date"
                      :readonly="!editMode || item.disable"
                      :rules="[]"
                      :clearable="true"
                      dense
                      :hide-details="item.chk_installation_date"
                    ></DatePicker>
                    <span v-if="item.chk_installation_date" class="caption text-error">前回:{{item.prev_installation_date | formatDate}}</span>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.warehouse_mlit}} -->
                    <NumberTextField
                      :propClass="['mb-4']"
                      v-model="item.warehouse_mlit"
                      :readonly="!editMode || item.disable"
                      :rules="[maxValIf((item.disable != true), 99), minValIf((item.disable != true), 0)]"
                      dense
                      :toLocaleFlg="false"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.warehouse_mlit_branch}} -->
                    <NumberTextField
                      :propClass="['mb-4']"
                      v-model="item.warehouse_mlit_branch"
                      :readonly="!editMode || item.disable"
                      :rules="[maxValIf((item.disable != true), 99), minValIf((item.disable != true), 0)]"
                      dense
                      :toLocaleFlg="false"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.warehouse_pref}} -->
                    <NumberTextField
                      :propClass="['mb-4']"
                      v-model="item.warehouse_pref"
                      :readonly="!editMode || item.disable"
                      :rules="[maxValIf((item.disable != true), 47), minValIf((item.disable != true), 1)]"
                      dense
                      :toLocaleFlg="false"
                    ></NumberTextField>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.branch}} -->
                    <NumberTextField
                      :propClass="['mb-4']"
                      v-model="item.branch"
                      :readonly="!editMode || item.disable"
                      :rules="[requiredIf(() => !item.disable), maxValIf((item.disable != true), 99), minValIf((item.disable != true), 0), checkUnique(index, (item.disable != true))]"
                      dense
                      :toLocaleFlg="false"
                      :changeEvt="()=>{$refs.form.validate();}"
                    ></NumberTextField>
                  </td>
                  <td :class="[(item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.branch_name}} -->
                    <v-text-field
                      :class="(item.chk_type) ? 'mt-0 pt-0' : 'mb-4'"
                      v-model="item.branch_name"
                      :readonly="!editMode || item.disable"
                      :rules="[maxLengthIf((item.disable != true), 100)]"
                      dense
                      :hide-details="item.chk_type"
                      :maxLength="100"
                    ></v-text-field>
                    <span v-if="item.chk_type" class="caption text-error">前回:{{item.prev_branch_name}}</span>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.type}} -->
                    <NumberTextField
                      :propClass="[(item.chk_type) ? 'mt-0 pt-0' : 'mb-4']"
                      v-model="item.type"
                      :readonly="!editMode || item.disable"
                      :rules="[maxValIf((item.disable != true), 99), minValIf((item.disable != true), 0)]"
                      dense
                      :hide-details="item.chk_type"
                      :toLocaleFlg="false"
                    ></NumberTextField>
                    <span v-if="item.chk_type" class="caption text-error">前回:{{item.prev_type}}</span>
                  </td>
                  <td :class="['right', (item.disable == 1) ? 'disable-color' : '']">
                    <!-- {{item.volume}} -->
                    <NumberTextField
                      :propClass="[(item.chk_volume) ? 'mt-0 pt-0' : 'mb-4']"
                      v-model="item.volume"
                      :readonly="!editMode || item.disable"
                      :rules="[maxValLocaleJPIf((item.disable != true), 999999999), minValLocaleJPIf((item.disable != true), 0)]"
                      dense
                      :hide-details="item.chk_volume"
                    ></NumberTextField>
                    <span v-if="item.chk_volume" class="caption text-error">前回:{{item.prev_volume}}</span>
                  </td>
                </tr>
              </tbody>
            </template>

          </v-data-table>
        </v-col>
      </v-row>


    </v-container>

    <!------------------------------------------------------------------------>
    <!-- ぐるぐる -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" height="auto" id="vm--modal">
      <div>
        <h3 class="center mt-5 action-message">{{actionMessage}}</h3> 
        <Spinner size="150" class="spinner" ></Spinner>
      </div>
    </modal>

  </v-form>

</template>

<script>
import Spinner from 'vue-simple-spinner'
import dayjs from "dayjs";
import Debug from '../../Lib/Debug'
import Const from '../../mixins/const';
import Message from "../../Lib/Message";

import DatePicker from "../common/DatePicker.vue";
import NumberTextField from "../common/Number_text_field.vue";

import MlitData from "../../model/mlitData";
import MlitOffice from "../../model/mlitOffice";

export default {
  components: {
    Spinner,
    DatePicker,
    NumberTextField,
  },

  mixins: [Const],

  data: () => ({
    valid : true,
    loading: false,

    // ファイル ドラッグアンドドロップ
    isEnter: false,

    // モード
    mode: 'new',

    // 国交省受領ファイル
    mlitFile: null,
    // 国交省登録情報ID
    mlitDataSid: null,
    // 取込日
    inputDate: '2023-03-01',

    // データ編集フラグ
    editMode: true,

    // テーブルヘッダー
    headers: [
        { text: '除外', align: 'center', sortable: true, value: 'disable', width: 70 },
        { text: '管理番号', align: 'center', sortable: true, value: 'manage_no', width: 110 },
        { text: '営業所管轄局', align: 'center', sortable: true, value: 'office_mlit', width: 110 },
        { text: '営業所管轄支局', align: 'center', sortable: true, value: 'office_mlit_branch', width: 110 },
        { text: '営業所都道府県', align: 'center', sortable: true, value: 'office_pref', width: 110 },
        { text: '発券区分', align: 'center', sortable: true, value: 'ticket', width: 110 },
        { text: '登録番号', align: 'center', sortable: true, value: 'regist_no', width: 110 },
        { text: '氏名又は名称', align: 'center', sortable: true, value: 'company_name', width: 200 },
        { text: '資本金又は出資の総額', align: 'center', sortable: true, value: 'capital', width: 150 },
        { text: '主たる営業所', align: 'center', sortable: true, value: 'office_main', width: 110 },
        { text: '主たる営業所の管轄局', align: 'center', sortable: true, value: 'office_main_mlit', width: 140 },
        { text: '営業所番号', align: 'center', sortable: true, value: 'office_no', width: 110 },
        { text: '営業所名称', align: 'center', sortable: true, value: 'office_name', width: 250 },
        { text: '営業所住所', align: 'center', sortable: true, value: 'office_address', width: 300 },
        { text: '倉庫番号', align: 'center', sortable: true, value: 'warehouse_no', width: 110 },
        { text: '倉庫名称', align: 'center', sortable: true, value: 'warehouse_name', width: 200 },
        { text: '設置年月日', align: 'center', sortable: true, value: 'installation_date', width: 120 },
        { text: '倉庫管轄局', align: 'center', sortable: true, value: 'warehouse_mlit', width: 110 },
        { text: '倉庫管轄支局', align: 'center', sortable: true, value: 'warehouse_mlit_branch', width: 110 },
        { text: '倉庫都道府県', align: 'center', sortable: true, value: 'warehouse_pref', width: 110 },
        { text: '区画番号', align: 'center', sortable: true, value: 'branch', width: 110 },
        { text: '区画名称', align: 'center', sortable: true, value: 'branch_name', width: 200 },
        { text: '類別', align: 'center', sortable: true, value: 'type', width: 110 },
        { text: '面積', align: 'center', sortable: true, value: 'volume', width: 120 },
    ],

    // 取り込み国交省データ
    inputData: [],
    mokInputData: [
      {
        disable: false, checkCount: 2,
        manage_no: 1, office_mlit: 1, office_mlit_branch: 2, office_pref: 1, ticket: 1, regist_no: 1, company_name: '株式会社北海道日水12', capital: 345,
        office_main: 1, office_main_mlit: 1, office_no: 1, office_name: '株式会社北海道日水札幌工場', office_address: '札幌市西区二十四軒１条２丁目４番２５号',
        warehouse_no: 1, warehouse_name: '第１号冷蔵庫', installation_date: '1980-07-17', warehouse_mlit: 1, warehouse_mlit_branch: 2, warehouse_pref: 1,
        branch: 1, branch_name: '第１号冷蔵庫', type: 13, volume: 9999,
        prev_company_name: '株式会社北海道日水', prev_capital: 12345, 
        prev_office_address: '札幌市西区二十四軒１条２丁目４番２５号', prev_warehouse_no: 1,
        prev_branch_name: '第１号冷蔵庫', prev_type: 13,
        prev_volume: 9999,
        chk_capital: true, chk_warehouse_no: true, chk_type: false, chk_volume: false,
      },
      {
        disable: false, checkCount: 0,
        manage_no: 1, office_mlit: 1, office_mlit_branch: 2, office_pref: 1, ticket: 1, regist_no: 1, company_name: '株式会社北海道日水12', capital: 345,
        office_main: 1, office_main_mlit: 1, office_no: 1, office_name: '株式会社北海道日水札幌工場', office_address: '札幌市西区二十四軒１条２丁目４番２５号',
        warehouse_no: 1, warehouse_name: '第１号冷蔵庫', installation_date: '1980-07-17', warehouse_mlit: 1, warehouse_mlit_branch: 2, warehouse_pref: 1,
        branch: 1, branch_name: '第１号冷蔵庫', type: 13, volume: 9999,
        prev_company_name: '株式会社北海道日水', prev_capital: 12345, 
        prev_office_address: '札幌市西区二十四軒１条２丁目４番２５号', prev_warehouse_no: 1,
        prev_branch_name: '第１号冷蔵庫', prev_type: 13,
        prev_volume: 9999,
        chk_capital: false, chk_warehouse_no: false, chk_type: false, chk_volume: false,
      },
      {
        disable: false, checkCount: 0,
        manage_no: 1, office_mlit: 1, office_mlit_branch: 2, office_pref: 1, ticket: 1, regist_no: 1, company_name: '株式会社北海道日水12', capital: 345,
        office_main: 1, office_main_mlit: 1, office_no: 1, office_name: '株式会社北海道日水札幌工場', office_address: '札幌市西区二十四軒１条２丁目４番２５号',
        warehouse_no: 1, warehouse_name: '第１号冷蔵庫', installation_date: '1980-07-17', warehouse_mlit: 1, warehouse_mlit_branch: 2, warehouse_pref: 1,
        branch: 1, branch_name: '第１号冷蔵庫', type: 13, volume: 9999,
        prev_company_name: '株式会社北海道日水', prev_capital: 12345, 
        prev_office_address: '札幌市西区二十四軒１条２丁目４番２５号', prev_warehouse_no: 1,
        prev_branch_name: '第１号冷蔵庫', prev_type: 13,
        prev_volume: 9999,
        chk_capital: false, chk_warehouse_no: false, chk_type: false, chk_volume: false,
      },
    ],

    // 重複データチェック用
    idList: [],

    // 作成時点重複チェック用
    inputDateList: [],

    // ぐるぐるメッセージ
    actionMessage: '',
  }),

  created: function() {
  },

  mounted : function() {
    this.inputDate = dayjs().format('YYYY-MM-DD');  // 作成時点

    // 国交省登録情報作成時点一覧
    this.loadInputDateList();
  },

  methods: {
    async loadInputDateList() {
      Debug.log("function[loadInputDateList]");

      this.loading = true;
      // DBから一覧取得
      MlitData.inputDataList()
        .then(res => {
          if (res && res.data) {
            this.inputDateList = res.data;
            Debug.log(this.inputDateList);
          }
        })
        .catch((err) => {
          Message.err(err, "国交省登録情報作成時点一覧を取得できませんでした");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // 国交省受領ファイルアップロード
    async uploadMlitFile() {
      // ファイルをサーバにアップロードする
      Debug.log('function[uploadMlitFile]');
      Debug.log2('mlitFile:', this.mlitFile);

      if(this.checkInputDate()) {
        if (!confirm(dayjs(this.inputDate).format('YYYY年M月D日')+"のデータは既に存在します。データを取り込みますか？")) return;
      }

      this.actionMessage = "データチェックを行っています。\nしばらくお待ちください。"
      this.$modal.show('modal-dialog');

      this.inputData = [];

      try {
        let res = null;
        res = await MlitOffice.inputMlitOffice(this.inputDate, this.mlitFile);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data.validator;
        if(validationMsg) {
          alert(validationMsg);
        } else if(res.status == 200) {
          Debug.log2("mlitData:", res.data.dataList);
          this.inputData = res.data.dataList;
          Debug.log2("filterItems length:", this.filterItems.length);

          this.$nextTick(() => {
            this.$refs.form.validate();
          });

          //this.inputData = this.mokInputData;
        }
      } catch (error) {
        Message.err(error, "アップロードできませんでした");
      } finally {
        Debug.log("finally");
        this.$modal.hide('modal-dialog');
      }
    },

    // キャンセル
    cancel() {
      if (!confirm("変更をキャンセルします。よろしいですか？")) return;
    },
    // 保存
    async registData() {
      this.validation();

      if (!this.$refs.form.validate()) {
        alert("入力に誤りがあります。内容をご確認ください。");
        return;
      }

      if(this.checkInputDate()) {
        if (!confirm(dayjs(this.inputDate).format('YYYY年M月D日')+"のデータは既に存在します。上書きしてよろしいですか？")) return;
      } else {
        if (!confirm("データを保存します。よろしいですか？")) return;
      }

      this.actionMessage = "データを保存しています。\nしばらくお待ちください。"
      this.$modal.show('modal-dialog');

      // this.loading = true;
      try {
        const res = await MlitOffice.setDataList(this.mode, this.mlitDataSid, this.inputDate, this.inputData);

        // サーバーサイドのバリデーション 
        const validationMsg = res.data.validator;
        Debug.log2('validationMsg:', validationMsg);
        if (validationMsg) {
          let message = "";
          if (Array.isArray(validationMsg)) {
            validationMsg.forEach(m => message += m + '\n')
          } else {
            message = validationMsg;
          }
          alert(message);

          // this.loading = false;
          return;
        } else {
          alert("保存しました。事業所データ一覧画面に移動します。");
          this.$router.push({name: 'MlitOfficeList', params:{mode: 'edit', editId: res.data.sid}});
        }
      } catch (error) {
        Message.err(error, "保存できませんでした");
      } finally {
        this.$modal.hide('modal-dialog');
      }

      // this.loading = false;
    },

    // NG判定件数の計算
    calcCheckCount(index) {
      let count = 0;

      if(this.inputData[index].chk_capital == true) {
        count++;
      }
      if(this.inputData[index].chk_warehouse_no == true) {
        count++;
      }
      if(this.inputData[index].chk_type == true) {
        count++;
      }
      if(this.inputData[index].chk_volume == true) {
        count++;
      }
      if(this.required(this.inputData[index].regist_no) !== true) {
        count++;
      }
      if(this.required(this.inputData[index].office_no) !== true) {
        count++;
      }
      if(this.required(this.inputData[index].warehouse_no) !== true) {
        count++;
      }
      if(this.required(this.inputData[index].branch) !== true) {
        count++;
      }
      if(this.checkUnique(index) !== true) {
        count++;
      }
      if(this.inputData[index].chk_installation_date == true) {
        count++;
      }
      if(this.inputData[index].chk_regist_no == true) {
        count++;
      }
      
      this.$set(this.inputData[index], 'checkCount', count);
    },

    // unique チェック用 idListを作成
    makeIdList() {
      this.idList = this.inputData.map(value => {
        return [value.disable, value.regist_no, value.office_no, value.warehouse_no, value.branch];
      });
    },

    // 主キーのバリデーション
    checkUnique(index, ifFlg=true) {
      if(!ifFlg) return true;

      if ( this.inputData[index].validateMsg != '') {
        return this.inputData[index].validateMsg;
      } else {
        return true;
      }
    },

    // Uniqueチェック
    validation() {
      // id の uniqueチェック用
      this.makeIdList();
      this.inputData.forEach((data, i) => {
        let result = false;
        // if( (!data.disable) && (data.errCnt > 0) ) {
        if(!data.disable) {
          this.idList.forEach((value, i2) => {
            if ((i != i2) &&
                  (value[0] == false) && 
                  (value[1] == data.regist_no) && 
                  (value[2] == data.office_no) &&
                  (value[3] == data.warehouse_no) &&
                  (value[4] == data.branch)) {
              result = true;
            }
          });
        }

        let msg = '';
        if (result) {
          msg = '重複データがあります。';
        }

        this.$set(this.inputData[i], 'validateMsg', msg);
      });
    },

    // 作成時点重複チェック
    checkInputDate() {
      let result = false;
      Debug.log2('inputDate:',this.inputDate);
      this.inputDateList.forEach(value => {
        Debug.log2('inputDateList:',value);
        if(value == this.inputDate) {
          result = true;
        }
      })
      return result;
    },
  },

  computed: {
    filterItems() {
      // if (this.items.length > 0) {
      //   return this.$refs.ref.$children[0].filteredItems;
      // } else {
      //   return [];
      // }
      return this.inputData.filter(item => item.errCnt > 0);
    },
  },

  watch: {
    inputData: {
      handler(val) {
        val.forEach((data, index) => {
          this.$nextTick(() => {
            this.calcCheckCount(index);
          });
        });
      },
      deep: true
    },
  },

}
</script>


<style scoped>
.v-data-table >>> .v-data-table__wrapper th {
  padding: 0 !important;
}
.v-data-table >>> .v-data-table__wrapper td {
  padding: 0px 3px 0px 3px;
}

.v-data-table >>> .v-data-table__wrapper .v-text-field {
    padding-top: 6px;
}
.v-data-table >>> .v-data-table__wrapper .v-input .v-input__slot {
    margin-bottom: 2px;
}

/** テーブルの縦横固定 */
.v-data-table--fixed-header >>> th:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(1) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 0;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(1) {
    background: #ffffff;
}

.v-data-table--fixed-header >>> th:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 70px;
    top: 0;
    z-index: 3;
}
.v-data-table--fixed-header >>> .tr-data td:nth-child(2) {
    position: sticky !important;
    position: -webkit-sticky !important;
    left: 70px;
    z-index: 2;
}
.v-data-table--fixed-header >>> .tr-data.enable td:nth-child(2) {
    background: #ffffff;
}

/* ぐるぐる */
.action-message {
  white-space: pre-line;
}
.spinner {
  margin: 50px;
}
</style>
